* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
/* 
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

[yellow] {
  color: rgba(236, 167, 30, 1);
}

[yellow-dark] {
  color: #eca71e;
}

[white] {
  color: "white";
}
[data-color-white] {
  color: "white";
}

[color="white"] {
  color: #ffffff;
}
[b9-nowrap] {
  white-space: wrap;
}
:root {
  --swiper-navigation-color: #fecc1d;
  --swiper-theme-color: #fecc1d;
}

.hero-img-background:before {
  content: " ";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("/static/hero-background3.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background-position: left top; */
  background-position: center top;
}

@media (min-width: 900px) {
  .hero-img-background:before {
    /* background-position: right top; */
  }
}

@media (min-width: 600px) {
  [b9-nowrap] {
    white-space: nowrap;
  }
}

.filter-swiper-slide {
  filter: opacity(0.5);
}

.filter-swiper-slide-active {
  filter: none;
}

/* 

@media (min-width: 1200px) {

} */

.feature-image {
  object-fit: contain;
}
